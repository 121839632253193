import PageEntity from '~/entities/Page.entity'
import TaxonEntity from '~/entities/Taxon.entity'

export const useNewsStore = defineStore({
  id: 'news-store',
  state: () => {
    return {
      list: null,
      total: null,
      post: null,
      categories: null
    }
  },
  actions: {
    fetchNews(params = {}) {
      return useApi().news.getAll(params, {
        onSuccess: res => {
          this.list = PageEntity.parseApiResponse(res)?.data
          this.total = PageEntity.parseApiResponse(res)?.totalItems
        }
      })
    },
    fetchNewBySlug(slug) {
      return useApi().news.getOne(slug, {
        onSuccess: res => {
          this.post = res
        },
        onError: res => {
          if (res.code === 410) {
            navigateTo(useLocalePath()('/post/'))
          }
          //  else {
          //   throw createError({
          //     statusCode: 404,
          //     statusMessage: 'Page Not Found'
          //   })
          // }
        }
      })
    },
    fetchCategories() {
      return useApi().news.getCategories({
        onSuccess: res => {
          this.categories = new TaxonEntity(res)
        }
      })
    }
  },
  getters: {
    newsList: state => state.list,
    newsTotal: state => state.total,
    postData: state => new PageEntity(state.post),
    otherPosts: function () {
      return id => this.list?.filter(post => post.id !== id)
    },
    categoriesList: state => state.categories
  }
})
