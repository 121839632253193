import { DateTime } from 'luxon'
import Entity from './Entity'
import ProductEntity from './Product.entity'
import ImageEntity from './Image.entity'

type PostContent = {
  id?: number
  content?: string
  metaDescription?: string
  metaKeywords?: string
  name?: string
  slug?: string
  image?: ImageEntity
}

type Translations = {
  uk?: PostContent
}
class PageEntity extends Entity {
  '@id'?: string
  id?: number
  translations?: Translations
  code?: string
  publishAt?: string
  products?: ProductEntity[]
  content?: string
  metaDescription?: string
  metaKeywords?: string
  name?: string
  slug?: string
  image?: ImageEntity

  constructor(payload: Partial<PageEntity>) {
    super(payload)
    Object.assign(this, payload)
  }

  getDate() {
    return this.publishAt
      ? DateTime.fromFormat(this.publishAt, 'yyyy-LL-dd HH:mm:ss').toFormat(
        'dd.LL.yyyy'
      )
      : ''
  }

  getPromotionDate(date: string) {
    return date
      ? DateTime.fromFormat(date, 'yyyy-LL-dd HH:mm:ss').toFormat('dd.LL.yyyy')
      : ''
  }

  getMainImage() {
    return this.translations?.uk?.image?.path
  }
}

export default PageEntity
